import React, {Component} from "react";
import HomePage from "./home";

class App extends Component {
      
      render() {
        return (
          <>
            <HomePage/>
          </>
        );
      }
}
export default App

